import React, {useState} from 'react';
import {generateUuidv4} from "../../../utils/utils";
import {ReactComponent as CheckIcon} from "../../../resources/check_in_circle.svg";
import {firebaseGet, firebaseSet} from "../../FireBase/firebase";

export const NewCount = (props) => {
    const [existing, setExisting] = useState('');
    const [existingError, setExistingError] = useState('');
    const [newName, setNewName] = useState('');
    const [newError, setNewError] = useState('');

    const addLocalCount = (id) => {
        let counts = localStorage.getItem('counts');
        counts = counts ? JSON.parse(counts) : [];
        if (!counts.includes(id))
            counts.push(id);
        localStorage.setItem('counts', JSON.stringify(counts));
        props.onChange();
        props.router.history.replace(`/counts/${id}`);
    }

    const renderInput = (
        value,
        setter,
        label
    ) => {
        const id = generateUuidv4();
        return (
            <>
                <label htmlFor={id}>
                    {label}
                </label>
                <input
                    id={id}
                    type="text"
                    value={value}
                    onChange={e => setter(e.target.value)}
                    style={{dataLpignore: true}}
                />
            </>

        )
    }

    const renderConfirmButton = (
        onClick,
        disabled
    ) => {
        return (
            <button
                className={`confirmButton${disabled ? ' disabled' : ''}`}
                onClick={onClick}
            >
                <CheckIcon/>
            </button>
        )
    }

    const getId = text => text.trim().replaceAll(' ', '_').toLowerCase();

    return (
        <div className="page newCount">
            <h1>Ny telling</h1>
            <div className="rounded thing">
                <h3>Opprett ny</h3>
                {renderInput(
                    newName,
                    val => {
                        setNewName(val);
                        newError && setNewError('');
                    },
                    'Navn på den nye listen'
                )}
                {
                    newError && newError.length > 0 ? (
                        <p className="rounded newCount__error">
                            {
                                newError.map((val, i) => (
                                        <span key={i}>{val}</span>
                                    )
                                )
                            }
                        </p>
                    ) : renderConfirmButton(
                    () => {
                        let id = getId(newName);
                        firebaseGet(['counts', id])
                            .then(foo => {
                                if (foo) {
                                    setNewError(['Tellingen finnes fra før.', 'Legg den til (under) eller velg et annet navn.']);
                                } else {
                                    firebaseSet(['counts', id], {
                                        name: newName,
                                        id: id
                                    })
                                    addLocalCount(id);
                                }
                            })
                    },
                    newName.length === 0
                )}
            </div>

            <div className="rounded thing">
                <h3>Koble til en eksisterende</h3>
                <p>Hvis du har laget listen fra før, for eksempel på en annen telefon.</p>

                {renderInput(
                    existing,
                    val => {
                        setExisting(val);
                        existingError && setExistingError('');
                    },
                    'Navn på listen du vil legge til'
                )}

                {
                    existingError && existingError.length > 0 ? (
                        <p className="rounded newCount__error">
                            {
                                existingError.map((val, i) => (
                                            <span key={i}>{val}</span>
                                        )
                                    )
                            }
                        </p>
                    ) : renderConfirmButton(
                        () => {
                            let id = getId(existing);
                            firebaseGet(['counts', id])
                                .then(foo => {
                                    if (!foo) {
                                        setExistingError(['Fant ikke tellingen.', 'Skrev du rett navn?']);
                                    } else {
                                        addLocalCount(id);
                                    }
                                })
                        },
                        existing.length === 0
                    )
                }
            </div>
        </div>
    );
}