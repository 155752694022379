import React from 'react';

export const Modal = ({
    children, close
                      }) => (
    <div className="modal">
        <div className="rounded thing modalContent">
            {children}
        </div>
        <div
            className="modalBg"
            onClick={close}
        />
    </div>
);