import React, {useState} from 'react';

export const Exercise = ({
                             exercise,
                             setExercise,
                             editing,
                             className,
                             sessionIndex,
                             sessions
                         }) => {
    const [showHistory, setShowHistory] = useState(false);
    const {name, sets, reps, weight} = exercise;


    if (!editing && (!reps || !weight || !sets))
        return null;


    const setThing = (key, value) => setExercise({
        ...exercise,
        [key]: value
    })

    const renderThing = (key, value) => (
        editing ? (<input
            type="number"
            value={value ?? ''}
            onChange={(e) => {
                setThing(key, e.target.value)
            }}
        />) : value
    )

    let history = null;

    (() => {
        if (!sessions) return;
        // if (name !== 'Kranglesprang') return;
        const v = Object.values(sessions)
        const f = (v.filter((s, i)  => {
            return i > sessionIndex &&  s.exercises.some(e =>
                e.name.toLowerCase() === name.toLowerCase() &&
                !!e.weight &&
                !!e.reps &&
                !!e.sets
            );
        }) ?? []).slice(0, 4);
        if (f.length === 0) return;
        const h = f.map(s => s.exercises.find(e => e.name === name));
        history = <div className="history" onClick={() => setShowHistory(false)}>
            {h.map((prev, i) => (
                <div key={`old-${prev.name}-${i}`} className="previousSession">
                    <span>{prev.weight}</span> kg x <span>{prev.reps}</span> x <span>{prev.sets}</span>
                </div>
            ))}
        </div>
    })();



    return (
        <div className={`exercise ${className}`}>
            <div className="main">
                <div
                    className={`exerciseName ${history ? 'withHistory' : ''}`}
                    onClick={() => {
                        if (!history) return;
                        setShowHistory(prev => !prev);
                    }}
                >
                    {name}
                </div>
                <div>
                    {renderThing('weight', weight)}
                    {' kg x '}
                    {renderThing('reps', reps)}
                    {' x '}
                    {renderThing('sets', sets)}
                </div>
            </div>
            {showHistory && history}
        </div>
    );
}