import React from 'react';
import moment from "moment";

let timeout, touchStartTime;
export const LongPressButton = ({className, onPress, onLongPress, children}) => {
    const clear = () => {
        timeout && clearTimeout(timeout);
        touchStartTime = undefined;
    }

    const down = () => {
        touchStartTime = moment();
        timeout = setTimeout(() => {
            if (!onLongPress) return;
            clear();
            onLongPress();
        }, 500)
    }

    const up = () => {
        moment() - touchStartTime < 500 && onPress && onPress();
        clear();
    }

    return (
        <button
            className={`longPressButton ${className}`}
            onTouchStart={down}
            onTouchEnd={up}
        >
            {children}
        </button>
    );
}