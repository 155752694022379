import React, {useEffect, useState} from 'react';
import {firebaseSet, listen} from "../../FireBase/firebase";
import {FourOhFour} from "../../General/FourOhFour";
import {EditCountDatum} from "./EditCountDatum";
import {getWeekId, idToMoment} from "../../../utils/utils";
import {Week} from "./Week";
import {DeleteButton} from "../../General/DeleteButton/DeleteButton";
import {Loader} from "../../General/Loader/Loader";

export const Count = (props) => {
    const [count, setCount] = useState();
    const [editing, setEditing] = useState(-1);
    const [weeks, setWeeks] = useState();


    const id = props.router.match.params.id;

    useEffect(() => {
        listen(['counts', id], val => {
            if (!val) {
                return;
            } else if (!val.data) {
                setWeeks({});
                setCount(val);
                return;
            }
            let newVal = {...val};

            newVal.data = {};
            const w = {}
            for (let key of Object.keys(val.data)) {
                const newDatum = {...val.data[key]}
                const date = newDatum.date = idToMoment(newDatum.id);
                newVal.data[key] = newDatum;
                const wId = getWeekId(date);
                if (!w[wId])
                    w[wId] = []
                w[wId].push(newDatum)
            }
            setWeeks(w)
            setCount(newVal);
        });
    }, [id])

    if (count === null)
        return <FourOhFour/>

    let content = <>
        <Loader/>
    </>


    const renderDeleteButton = () => {
        const prompt = 'Fjerne denne telletingen?';
        return (
            <DeleteButton
                clear
                prompt={prompt}
                onConfirm={() => {
                    localStorage.setItem('counts',
                        JSON.stringify(
                            JSON.parse(localStorage.getItem('counts'))
                                .filter(c => c !== count.id)
                        )
                    );
                    props.onDelete();
                    props.router.history.replace('/');
                }
                }
            />
        )
    }

    const renderEditDatum = () => {
        const close = () => setEditing(-1);
        const countId = props.router.match.params.id;
        return (
            <EditCountDatum
                key={editing}
                editing={editing}
                data={count.data || []}
                close={close}
                submit={datum =>
                    firebaseSet(['counts', countId, 'data', datum.id], {
                        id: datum.id,
                        quantity: datum.quantity
                    })
                }
                remove={id => firebaseSet(['counts', countId, 'data', id])}
            />
        )
    }

    const renderWeek = (key, week) => {

        return (
            <Week
                key={key}
                week={week}
                weekKey={key}
                renderEditDatum={renderEditDatum}
                setEditing={setEditing}
                editing={editing}
            >
                Uke {key.split(':')[1]}
            </Week>
        )
    }

    const renderWeeks = () => {
        if (!weeks) return <></>;
        let ary = [];
        for (let key of Object.keys(weeks).sort().reverse()) {
            ary.push(renderWeek(key, weeks[key]));
        }
        return ary;
    }
    if (count)
        content = (
            <>
                <h1>{count.name}</h1>
                {
                    editing !== -2 ? (
                        <button
                            className="thing addButton"
                            onClick={() => setEditing(-2)}
                        />
                    ) : (
                        renderEditDatum()
                    )
                }
                {renderWeeks()}
            </>
        )

    return (
        <div className="page count">
            <div className="pageContent">
                {renderDeleteButton()}
                {content}
            </div>
        </div>
    );
}