import React, {useState} from 'react';
import {SingleDatePicker} from 'react-dates';
import moment from "moment";
import useEffectOnce from "../../../utils/UseEffectOnce";
import {dateFormat} from "../../../utils/utils";

export const DatePicker = (props) => {
    const [date, setDate] = useState(moment(props.date));
    const [dateFocused, setDateFocused] = useState(false);

    useEffectOnce(() => {
            props.onDateChange &&
            !props.date &&
            props.onDateChange(date)
        }
    )

    return (
        <SingleDatePicker
            id="your_unique_id"
            date={date}
            onDateChange={date => {
                setDate(date)
                props.onDateChange(date)
            }}
            numberOfMonths={1}
            focused={dateFocused}
            onFocusChange={({focused}) => setDateFocused(focused)}
            readOnly={true}
            firstDayOfWeek={1}
            isOutsideRange={() => false}
            displayFormat={dateFormat}
        />
    );
}