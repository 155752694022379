import React from 'react';
import {Modal} from "../Modal/Modal";

export const DeleteModal = ({prompt, confirm, close}) => (
    <Modal close={close}>
        <p>{prompt}</p>
        <div className="buttonGroup">
        <button
            className="rounded thing deletePromptButton"
            onClick={confirm}
        >
            Kjør på!
        </button>
        <button
            className="rounded thing deletePromptButton"
            onClick={close}
        >
            Nej!
        </button>
        </div>
    </Modal>
);