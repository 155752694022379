import React, {useState} from 'react';
import {Modal} from "../../General/Modal/Modal";

export const AddModal = ({
    heading,
    close,
    confirm,
                         }) => {
    const [newTypeName, setNewTypeName] = useState('');
    return (
        <Modal close={close}>
            <p>{heading}</p>
            <input
                type="text"
                autoFocus
                value={newTypeName}
                onChange={e => {
                    setNewTypeName(e.target.value);
                }}
            />
            <button
                className="rounded thing"
                onClick={() => confirm(newTypeName, close)}
            >
                Ok
            </button>
        </Modal>
    );
}