import React, {useState} from 'react';
import {ReactComponent as Delete} from '../../../resources/delete.svg';
import {ReactComponent as Clear} from '../../../resources/clear.svg';
import {DeleteModal} from "../DeleteModal/DeleteModal";

export const DeleteButton = ({
    children,
    clear,
    prompt,
    onConfirm
                             }) => {
    const [show, setShow] = useState(false);

    const icon = clear ? <Clear/> : <Delete/>

    return (
        <>
            {show && <DeleteModal
                close={() => setShow(false)}
                confirm={onConfirm}
                prompt={prompt}
            />}
            <div
                className="thing deleteButton"
                onClick={() => setShow(true)}
            >
                {children ?? icon}
            </div>
        </>
    );
}