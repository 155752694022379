import React, {useState} from 'react';
import {formatDate} from "../../../../utils/utils";
import moment from "moment/moment";
import {Exercise} from "../Exercise";
import {DatePicker} from "../../../General/DatePicker/DatePicker";
import {DeleteModal} from "../../../General/DeleteModal/DeleteModal";
import {AddModal} from "../AddModal";
import {CloseButton} from "../CloseButton";
import {SortableList} from "../../../SortableList/SortableList";

let deleteTimeout;

export const Session = ({setExercises, session, updateExercise, setSessionDate, deleteSession, addExercise, sessions, sessionIndex}) => {
    const [editing, setEditing] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [adding, setAdding] = useState(false);

    const touchMove = () => {
        if (!deleteTimeout) return;
        deleteTimeout = clearTimeout(deleteTimeout);
    }

    const touchStart = () => {
        deleteTimeout = setTimeout(() => {
            setDeleting(true);
            deleteTimeout = undefined;
        }, 1000);
    }

    const touchEnd = () => {
        deleteTimeout = clearTimeout(deleteTimeout);
    }

    const renderDeleteModal = () => {
        if (!deleting) return null;
        return (
            <DeleteModal
                prompt={`Slette treningssessjon ${formatDate(moment(session.date), true)}?`}
                close={() => setDeleting(false)}
                confirm={deleteSession}
            />
        )
    }

    return (
        <>
            {renderDeleteModal()}
            <div
                className={`session rounded thing ${editing ? 'editing' : ''}`}
                onClick={() => {
                    if (!editing) setEditing(true)
                }}
                onTouchStart={editing ? undefined : touchStart}
                onTouchMove={editing ? undefined : touchMove}
                onTouchEnd={touchEnd}
            >
                {
                    editing ? (
                        <>
                            <DatePicker
                                date={session.date}
                                onDateChange={d =>
                                    setSessionDate(d.startOf('day'))
                                }
                            />
                            <CloseButton onClick={() => setEditing(false)}/>
                        </>
                    ) : (
                        <h3>
                            {formatDate(moment(session.date))}
                        </h3>
                    )
                }
                {
                    editing ? (
                        <SortableList
                            items={session.exercises}
                            setItems={setExercises}
                            getId={i => i.name}
                            renderItem={(item, i) => (
                                <Exercise
                                    key={item.name}
                                    exercise={item}
                                    setExercise={(ne) => updateExercise(ne, i)}
                                    editing={editing}
                                    sessionIndex={sessionIndex}
                                    sessions={sessions}
                                />
                            )}
                        />
                    ) : session.exercises.map((e, i) => (
                        <Exercise
                            key={e.name}
                            exercise={e}
                            setExercise={(ne) => updateExercise(ne, i)}
                            editing={editing}
                            className="withMargins"
                        />
                    ))
                }

                {editing && <button
                    className="thing addButton"
                    onClick={() => setAdding(true)}
                />}
                {adding &&
                    <div className="tricky thing">
                        <AddModal
                            heading="Ny øvelse i dag:"
                            close={() => setAdding(false)}
                            confirm={(name, onSuccess) => {addExercise(name, onSuccess)}}
                        />
                    </div>
                }
            </div>
        </>

    );
}