import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyBSdTjN_emo_4KUUgYRvqMxpFXKPU2uGmU",
    authDomain: "fantastatistic.firebaseapp.com",
    projectId: "fantastatistic",
    storageBucket: "fantastatistic.appspot.com",
    messagingSenderId: "1084636574422",
    appId: "1:1084636574422:web:bd3794d607d0524c67cf77"
};

firebase.initializeApp(firebaseConfig);

const dbRef = firebase.database().ref();

export const listen = (key, callBack) => {
    let ref = dbRef;
    if (typeof key === 'string')
        ref = ref.child(key)
    else
        for (let i = 0; i < key.length; i++) {
            ref = ref.child(key[i]);
        }
    ref.on('value', snap => callBack(snap.val()));
}

export const firebaseSet = (keys, value) => {
    let ref = dbRef;
    for (let i = 0; i < keys.length; i++) {
        ref = ref.child(keys[i]);
    }
    if (value)
        ref.set(value);
    else
        ref.remove();
}

export const firebaseGet = (keys) => {
    return new Promise(resolve => {

        let ref = dbRef;
        for (let i = 0; i < keys.length; i++) {
            ref = ref.child(keys[i]);
        }

        ref.once('value').then(snap => {
            resolve(snap.val())
        })
    })
}

export const firebaseAdd = (key, value) => {
    const push = dbRef.child(key)
        .push();
    push.set(push.key, value);
}

