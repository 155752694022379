import React, {useState} from 'react';
import {ReactComponent as LoadSvg} from "../../../resources/Loader.svg";
import useEffectOnce from "../../../utils/UseEffectOnce";

let timeout;
export const Loader = () => {
    const [stage, setStage] = useState(0);
    const clear = () => timeout && clearTimeout(timeout)
    const next = (cs) => {
        setStage(cs)
        clear()
        const quik = cs % 2 === 0;

        timeout = setTimeout(() => {
            next(cs === 10 ? 1 : cs + 1)
        },  quik ? 300 : 800)
    }
    useEffectOnce(() => {
        next(0)
        return clear;
    })

    return (
        <div className={`page loader stage_${stage}`}>
            <LoadSvg/>
        </div>
    );
}