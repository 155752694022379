import React from 'react';

export const CloseButton = ({onClick}) => (
    <div
        className="closeButton"
        onClick={onClick}
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="36"
            width="36"
            viewBox="0 -960 960 960"
        >
            <path
                d="m296-80-56-56 240-240 240 240-56 56-184-184L296-80Zm184-504L240-824l56-56 184 184 184-184 56 56-240 240Z"
                fill="white"
            />
        </svg>
    </div>
);