import React, {useState} from 'react';
import './Menu.css';
import {HamburgerButton} from "./HamburgerButton";

export const Menu = (props) => {
    const [show, setShow] = useState(false);

    return (
        <>
            {
                show && (
                    <div
                        className="menuTouchOutsideSensor touchOutsideSensor"
                        onClick={() => setShow(false)}
                    />
                )
            }
            <button
                className={`rounded thing menuButton${show ? ' open' : ''}`}
                onClick={() => setShow(!show)}
            >
                <HamburgerButton
                    open={show}
                    transitionTime={400}
                />
            </button>
            <div
                className={`menu thing${show ? ' show' : ''}`}
                onClick={(e) =>
                    e.target.tagName === 'A' && setShow(false)
                }
            >
                {props.children}
            </div>
        </>
    );
}