import React, {useEffect, useState} from 'react';
import {formatDate, momentToId} from "../../../utils/utils";
import moment from "moment";
import {DatePicker} from "../../General/DatePicker/DatePicker";
import {ReactComponent as CheckIcon} from "../../../resources/check_in_circle.svg";
import useEffectOnce from "../../../utils/UseEffectOnce";
import {LongPressButton} from "../../General/LongPressButton";

export const EditCountDatum = ({
                                   editing,
                                   data,
                                   close,
                                   submit,
                                   remove
                               }) => {
    const [datum, setDatum] = useState();
    const [invalid, setInvalid] = useState(true);
    useEffectOnce(() => validate(moment()));

    useEffect((() => {
            if (editing === -1) {
                setDatum(null);
                return;
            }
            if (editing === -2) {
                validate(moment())
                setDatum({
                    id: momentToId(moment()),
                    date: formatDate(moment()),
                    quantity: 1
                })

            } else {
                const datum = data[editing];
                if (datum){
                    validate(datum.date)
                    setDatum(datum)
                }
            }
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [editing, data])

    const increment = val => {
        let quantity = datum.quantity + val;
        if (quantity < 0) quantity = 0;
        setDatum(old => {
            const newDatum = {
                ...old,
                quantity
            }
            editing === -2 || submit(newDatum);
            return newDatum;
        })
    }

    const renderCounter = () => {
        return (
            <div className="editCountDatum__counter">
                <LongPressButton
                    className="incrementButton"
                    onPress={() => increment(1)}
                    onLongPress={() => increment(.5)}
                >
                    <div className="arrow"/>
                </LongPressButton>
                <p className="editCountDatum__quantity">
                    {datum.quantity}
                </p>
                <LongPressButton
                    className={`decrementButton${datum.quantity === 0 ? ' disabled' : ''}`}
                    onPress={() => increment(-1)}
                    onLongPress={() => increment(-.5)}
                >
                    <div className="arrow"/>
                </LongPressButton>
            </div>
        )
    }

    const confirmOrError = () => (editing === -2) && (
        <button
            className={`confirmButton${invalid ? ' disabled' : ''}`}
            onClick={() => {
                if (invalid)
                    alert('Det finnes allerete en teller for denne datoen.');
                else {
                    datum.quantity > 0 && submit(datum);
                    close();
                }
            }}
        >
            <CheckIcon/>
        </button>
    )


    const validate = date => {
        if (editing !== -2){
            setInvalid(false);
            return;
        }
        for (let value of Object.values(data)) {
            if (date.isSame(value.date, 'day')){
                setInvalid(true);
                return;
            }
        }
        setInvalid(false);
    }

    const content = datum ? (
        <>
            <DatePicker
                date={moment(datum.date)}
                onDateChange={date => {
                    validate(date)
                    setDatum(datum => ({
                        ...datum,
                        id: momentToId(date),
                        date: formatDate(date),
                    }))
                }
                }
            />
            {renderCounter()}
            {confirmOrError()}
        </>) : (<></>)

    return (
        <>
            <div
                className="touchOutsideSensor"
                onClick={() => {
                    if (editing !== -2) {
                        if (datum.quantity === 0) {
                            remove(datum.id);
                        } else {
                            if (editing !== datum.id)
                                remove(editing);
                            submit(datum);
                        }
                    }
                    close();
                }}
            />
            <div className="rounded thing editCountDatum">
                {content}
            </div>
        </>
    );
}